<template>
  <span>
    <div @click="buttonClicked" class="absence-indicator" :class="absenceIndicatorColor">
      <span v-if="inscription.cancelledOn">
        <span v-if="inscription.reward" title="Annulation chantier">
          <i class="fas fa-cancel"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Annulation chantier</span>
        </span>
        <span v-else-if="inscription.invoiced" title="Annulation facturée">
          <i class="fas fa-cancel"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Annulation facturée</span>
        </span>
        <span v-else title="Annulation non-facturée">
          <i class="fas fa-cancel"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Annulation non-facturée</span>
        </span>
      </span>
      <span v-else-if="inscription.absence">
        <span v-if="inscription.reward" title="Absence chantier">
          <i class="fas fa-cancel"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Abs. chantier</span>
        </span>
        <span v-else-if="inscription.invoiced" title="Absence facturée">
          <i class="fas fa-donate"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Abs. facturée</span>
        </span>
        <span v-else  title="Absence non-facturée">
          <i class="far fa-frown-open"></i>
          <span :class="showLabel ? 'abs-label': 'hide-here'">Abs. non-facturée</span>
        </span>
      </span>
      <span v-else class="no-print" title="présent">
        <i class="fas fa-smile-beam"></i>
        <span v-if="showLabel" class="abs-label">Présent</span>
      </span>
    </div>
    <b-modal class="set-hour-modal" :id="getId()" size="lg">
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-check-circle"></i>&nbsp;
          <span v-if="isFuture">Confirmer une annulation</span>
          <span v-else>Confirmer une absence</span>
        </b>
      </template>
      <div v-if="!inscription.absence && !inscription.cancelledOn">
        <span v-if="isFuture">
          Voulez-vous annuler l'inscription de {{ individual.firstAndLastName() }}
          pour la séance {{ inscription.seance.name }}?
        </span>
        <span v-else>
          Voulez-vous signaler {{ individual.firstAndLastName() }} comme absent
          pour la séance {{ inscription.seance.name }}?
        </span>
        <div v-if="inscription.paidBy" class="paid-by-warning">
          L'inscription est incluse dans un forfait. En cas d'annulation non-facturée,
          souhaitez-vous, effectuer une réduction sur le forfait?
          <div>
            <br />
            <b-form-group description="Montant de la réduction">
              <decimal-input v-model="paidByDiscount"></decimal-input>
            </b-form-group>
          </div>
        </div>
        <div v-if="inscription.reward" class="paid-by-warning">
          L'inscription est un chantier. La récompense sera annulée.
        </div>
      </div>
      <div v-else-if="inscription.invoiced && !inscription.reward">
        Voulez-vous modifier <span v-if="inscription.cancelledOn">l'annulation</span><span v-else>l'absence</span>
        de {{ individual.firstAndLastName() }} pour la séance {{ inscription.seance.name }} comme non-facturée?
        <br /><br />
        La vente correspondante sera annulée
      </div>
      <div v-else-if="!inscription.reward">
        Voulez-vous modifier <span v-if="inscription.cancelledOn">l'annulation</span><span v-else>l'absence</span>
        de {{ individual.firstAndLastName() }} pour la séance {{ inscription.seance.name }} comme facturée?
        <br /><br />
        Une nouvelle vente sera générée
      </div>
      <div v-else-if="inscription.reward">
        Voulez-vous modifier <span v-if="inscription.cancelledOn">l'annulation</span><span v-else>l'absence</span>
        de {{ individual.firstAndLastName() }} pour la séance {{ inscription.seance.name }}?
        <br /><br />
        Une nouvelle récompense de chantier sera générée
      </div>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <template v-slot:modal-footer>
        <div class="modal-footer-buttons">
          <span v-if="isLocked">
            Les inscriptions sont figées
          </span>
          <span v-else>
            <a
              class="btn btn-absence presence"
              @click="signalAbsence(false, true)"
              v-if="inscription.absence || inscription.cancelledOn"
            >
              <i class="fas fa-smile-beam"></i>
              Restaurer la présence
            </a>
            <span v-if="inscription.reward">
              <a
                class="btn btn-absence invoiced"
                @click="signalAbsence(true, true)"
                v-if="!(inscription.absence || inscription.cancelledOn)"
              >
                <i class="fas fa-frown-open"></i>
                <span v-if="isFuture">
                  Annulation Chantier
                </span>
                <span v-else>
                  Absence Chantier
                </span>
              </a>
            </span>
            <span v-else>
              <a
                class="btn btn-absence invoiced"
                @click="signalAbsence(true, true)"
                v-if="!(inscription.absence || inscription.cancelledOn) || !inscription.invoiced"
              >
                <i class="fas fa-donate"></i>
                <span v-if="isFuture">
                  Annulation facturée
                </span>
                <span v-else>
                  Absence facturée
                </span>
              </a>
              <a
                class="btn btn-absence absence"
                @click="signalAbsence(true, false)"
                v-if="!(inscription.absence || inscription.cancelledOn) || inscription.invoiced"
              >
                <i class="far fa-frown-open"></i>
                <span v-if="isFuture">
                  Annulation non-facturée
                </span>
                <span v-else>
                  Absence non-facturée
                </span>
              </a>
            </span>
          </span>
          <a class="btn btn-secondary" @click="cancel()">
            Annuler
          </a>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceInscription, SeanceLock } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { getRandomId } from '@/utils/random'
import { compareDates } from '@/utils/sorting'

export default {
  name: 'AbsenceIndicator',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    inscription: Object,
    individual: Object,
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      randomId: getRandomId(),
      disabled: false,
      paidByDiscount: 0,
      errorText: '',
    }
  },
  watch: {
    individual: function() {},
    inscription: function() { this.disabled = false },
  },
  computed: {
    isLocked() {
      return (this.inscription) && (this.inscription.seance.lock === SeanceLock.Total)
    },
    absenceIndicatorColor() {
      if (this.inscription.absence || this.inscription.cancelledOn) {
        if (this.inscription.invoiced) {
          return 'invoiced'
        } else {
          return 'absence'
        }
      }
      return 'presence'
    },
    isFuture() {
      return compareDates(moment(this.inscription.seance.date).toDate(), moment().toDate()) >= 1
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    getId() {
      return this.randomId
    },
    buttonClicked() {
      this.$bvModal.show(this.getId())
    },
    async signalAbsence(absence, invoiced) {
      if (!this.disabled) {
        this.errorText = ''
        this.disabled = true
        let url = '/api/youth/set-absence/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            inscription: this.inscription.id,
            absence: absence,
            invoiced: invoiced,
            paid_by_discount: this.paidByDiscount,
          }
          const resp = await backendApi.callApi(data)
          this.$emit('updated', makeSeanceInscription(resp.data))
          let message = 'L\'absence a été prise en compte'
          if (this.isFuture) {
            message = 'L\'annulation a été prise en compte'
          }
          await this.addSuccess(message)
          this.$bvModal.hide(this.getId())
        } catch (err) {
          this.disabled = false
          this.errorText = this.getErrorText(err)
        }
      }
    },
    cancel() {
      this.$bvModal.hide(this.getId())
    },
  },
  mounted() {
    this.disabled = false
  },
}
</script>
<style lang="less">
.absence-indicator {
  padding: 2px 3px;
  border: solid 1px #888;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  min-width: 32px;
  text-align: center;
}
.absence-indicator.presence {
  background: #fffbd6;
  border: solid 1px #8b8a88;
  color: #000;
}
.absence-indicator.invoiced {
  background: #d00b4c;
  border: solid 1px #81002b;
  color: #fff;
}
.absence-indicator.absence {
  background: #e39600;
  border: solid 1px #d0870b;
  color: #000;
}
.btn-absence.invoiced {
  background: #d00b4c !important;
  border: solid 1px #81002b;
  color: #fff !important;
}
.btn-absence.absence {
  background: #e39600 !important;
  border: solid 1px #d0870b;
  color: #000 !important;
}
.btn-absence.presence {
  background: #fffbd6 !important;
  border: solid 1px #8b8a88 !important;
  color: #000 !important;
}

.abs-label {
  margin-left: 2px;
  font-size: 14px;
}
.paid-by-warning {
  margin: 10px 0;
  padding: 10px;
  background: #f2f2a2;
  font-weight: bold;
}
.modal-footer-buttons a{
  margin-left: 4px;
}
</style>
